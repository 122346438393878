<template>
    <div class="sum-distance-control">
        <div class="control">
            <el-select v-model="group" placeholder="请选择" size="mini">
                <el-option
                    v-for="item in groupOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
            </el-select>
        </div>
        <el-scrollbar>
            <div v-show="group == 1">
                <SumDistance :samples="samples()" />
            </div>
            <div v-show="group == 2">
                <h2>球：1-3</h2>
                <SumDistance :samples="samples(1, 3)" />
                <h2>球：4-6</h2>
                <SumDistance :samples="samples(4, 6)" />
            </div>
            <div v-show="group == 3">
                <h2>球：1-2</h2>
                <SumDistance :samples="samples(1, 2)" />
                <h2>球：3-4</h2>
                <SumDistance :samples="samples(3, 4)" />
                <h2>球：5-6</h2>
                <SumDistance :samples="samples(5, 6)" />
            </div>
            <div v-show="group == 6">
                <h2>球：1</h2>
                <SumDistance :samples="samples(1, 1)" />
                <h2>球：2</h2>
                <SumDistance :samples="samples(2, 2)" />
                <h2>球：3</h2>
                <SumDistance :samples="samples(3, 3)" />
                <h2>球：4</h2>
                <SumDistance :samples="samples(4, 4)" />
                <h2>球：5</h2>
                <SumDistance :samples="samples(5, 5)" />
                <h2>球：6</h2>
                <SumDistance :samples="samples(6, 6)" />
            </div>
        </el-scrollbar>
    </div>
</template>
<script>
import SumDistance from "./sumDistance.vue";
export default {
    name: "SumDistanceControl",
    components: {
        SumDistance
    },
    props: {
        historys: {
            type: Array,
            default: () => []
        }
    },
    watch: {},
    data() {
        return {
            group: 1,
            groupOptions: [
                { label: "不分区", value: 1 },
                { label: "二区", value: 2 },
                { label: "三区", value: 3 },
                { label: "六区", value: 6 }
            ]
        };
    },
    methods: {
        samples(start = 1, end = 6) {
            let simples = [];
            for (let item of this.historys) {
                let sum = 0;
                for (let i = start; i <= end; i++) {
                    sum += item["ball_0" + i];
                }
                simples.push(sum);
            }
            return simples.sort((o1, o2) => o1 - o2);
        }
    }
};
</script>
<style lang="scss" scoped>
.sum-distance-control {
    position: relative;
    height: 100%;
    .control {
        margin-bottom: 20px;
    }
}
</style>
